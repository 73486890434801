import { createRouter, createWebHistory } from 'vue-router'

import { auth } from '../firebase/config';
import getUserAccessStatus from "@/composables/Verification/getUserAccessStatus";
import getProfile from "@/composables/Profile/getProfile";
import { ref } from 'vue';

const { userHasAccess } = getUserAccessStatus()

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    next({ name: 'LogIn' })
  } else {
    next()
  }
}

const requireAccess = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    next({ name: 'LogIn' })
  } else {
    if (!userHasAccess.value) {
      alert("Access Denied: Verified NYU Email address required.")
      next({ name: 'Senior-AccountSettings' })
    }
    else {
      next()
    }
  }
}

const requireAdminAccess = async (to, from, next) => {
  let user = auth.currentUser;
  const profile = ref(null);
  await getProfile(profile, user.uid);

  if (profile.value.isAdmin) {
    next();
  } else {
    alert("Access Denied: You are not recognized as a VioLegacy Admin.")
    next({ name: 'Senior-AccountSettings' })
  }
}

const routes = [
  {
    path: '/',
    name: 'Official',
    component: () => import('../views/Official.vue')
  },
  {
    path: '/officialSenior',
    name: 'OfficialSenior',
    component: () => import('../views/OfficialSenior.vue')
  },
  {
    path: '/logIn',
    name: 'LogIn',
    component: () => import ('../views/LogIn.vue')
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import ('../views/SignUp.vue')
  },
  {
    path: '/studentSignUp',
    name: 'StudentSignUp',
    component: () => import ('../views/SignUp/StudentSignUp.vue')
  },
  {
    path: '/alumniSignUp',
    name: 'AlumniSignUp',
    component: () => import ('../views/SignUp/AlumniSignUp.vue')
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () => import ('../views/Verification.vue')
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import ('../views/Main.vue'),
    beforeEnter: requireAuth,
    children: [
      {
        path: '/main/senior/dashboard',
        name: 'Senior-Dashboard',
        component: () => import ('../views/Main/Dashboard.vue'),
      },
      {
        path: '/main/senior/myAcceptedWishesDetail',
        name: 'Senior-MyAcceptedWishesDetail',
        component: () => import ('../views/Main/MyAcceptedWishesDetail.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/senior/postOppDetail/:id',
        name: 'Senior-PostOppDetail',
        component: () => import ('../views/Main/PostOppDetail.vue'),
        props: true
      },
      {
        path: '/main/senior/postNewOpp',
        name: 'Senior-PostNewOpp',
        component: () => import ('../views/Main/PostNewOpp.vue'),
      },
      {
        path: '/main/senior/editPostOpp/:id',
        name: 'Senior-EditPostOpp',
        component: () => import ('../views/Main/EditPostOpp.vue'),
        props: true
      },
      {
        path: '/main/senior/wishlist',
        name: 'Senior-WishList',
        component: () => import ('../views/Main/WishListSenior.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/senior/wishcompany/:companyName/:companyId',
        name: 'Senior-WishCompany',
        component: () => import ('../views/Main/SeniorWishCompany.vue'),
        beforeEnter: requireAccess,
        props: true
      },
      {
        path: '/main/senior/wishdetail/:companyName/:companyId/:wishId',
        name: 'Senior-WishDetail',
        component: () => import ('../views/Main/SeniorWishDetail.vue'),
        beforeEnter: requireAccess,
        props: true
      },
      {
        path: '/main/senior/messages',
        name: 'Senior-Messages',
        component: () => import ('../views/Main/Messages.vue'),
      },
      {
        path: '/main/senior/FAQ',
        name: 'Senior-FAQ',
        component: () => import ('../views/Main/FAQ.vue'),
      },
      {
        path: '/main/senior/account-settings',
        name: 'Senior-AccountSettings',
        component: () => import ('../views/Main/AccountSettings.vue'),
      },
      {
        path: '/main/junior/home',
        name: 'Junior-Home',
        component: () => import ('../views/Main/Home.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/mywishesdetail',
        name: 'Junior-MyWishesDetail',
        component: () => import ('../views/Main/MyWishesDetail.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/wishlist',
        name: 'Junior-WishList',
        component: () => import ('../views/Main/WishList.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/wishcompany/:companyName/:companyId',
        name: 'Junior-WishCompany',
        component: () => import ('../views/Main/WishCompany.vue'),
        beforeEnter: requireAccess,
        props: true
      },
      {
        path: '/main/junior/opportunities',
        name: 'Junior-Opportunities',
        component: () => import ('../views/Main/Opportunities.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/oppDetail/:id',
        name: 'Junior-OppDetail',
        component: () => import ('../views/Main/OppDetail.vue'),
        beforeEnter: requireAccess,
        props: true
      },
      {
        path: '/main/junior/messages',
        name: 'Junior-Messages',
        component: () => import ('../views/Main/Messages.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/FAQ',
        name: 'Junior-FAQ',
        component: () => import ('../views/Main/FAQ.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/junior/account-settings',
        name: 'Junior-AccountSettings',
        component: () => import ('../views/Main/AccountSettings.vue'),
        beforeEnter: requireAccess,
      },
      {
        path: '/main/report',
        name: 'Report',
        component: () => import ('../views/Main/Report.vue'),
      },
      {
        path: '/main/admin-page',
        name: 'AdminPage',
        component: () => import ('../views/Main/AdminPage.vue'),
        beforeEnter: requireAdminAccess,
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
